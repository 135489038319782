import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

import {
  marketCapThunk,
  realVolumeThunk,
  ListingHoldersConcentrationKeysThunk,
  ListingHoldersConcentrationValueThunk,
} from "../../../Thunks";
import {
  getMarketCapData,
  getRealVolumeData,
  getListingHoldersConcentrationValueData,
  getListingMaturityValueData,
  getListingSecurityValueData,
  getListingGeneralValueData,
  getListingRegulationValueData,
} from "../../../Reducer/ListeningReducer";
import {
  ListingSearchAndFilter,
  ListingTable,
  Header,
} from "../../../Components";
import { ListingFilter } from "../../../Modal";
import {
  ListingGeneralKeysThunk,
  ListingGeneralValueThunk,
  ListingMaturityKeysThunk,
  ListingMaturityValueThunk,
  ListingRegulationKeysThunk,
  ListingSecurityKeysThunk,
  ListingSecurityValueThunk,
  generalThunk,
  holdersConcentrationThunk,
  marketCapKeysThunk,
  maturityThunk,
  realVolumeKeysThunk,
  regulationThunk,
  securityThunk,
} from "../../../Thunks/ListeningThunk";

export function Listing() {
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const openMenu = useSelector((state) => state.menu.open);
  const realVolumeData = useSelector(getRealVolumeData);
  const marketCapData = useSelector(getMarketCapData);
  const listingHoldersConcentrationValueData = useSelector(
    getListingHoldersConcentrationValueData
  );
  const listingMaturityValueData = useSelector(getListingMaturityValueData);
  const listingSecurityValueData = useSelector(getListingSecurityValueData);
  const listingGeneralValueData = useSelector(getListingGeneralValueData);
  const listingRegulationValueData = useSelector(getListingRegulationValueData);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [searchData, setSearchData] = useState("");

  useEffect(() => {
    if (!searchParams.get("tab")) {
      setSearchParams({ tab: 1 });
    }
  }, [searchParams.get("tab"), dispatch]);

  useEffect(() => {
    necessaryRequest();
  }, [searchData, searchParams.get("tab"), dispatch]);

  const generateTab = (number, text) => {
    return (
      <div
        className={`listening_tab_wrapper ${
          searchParams.get("tab") === number ? "active_tab" : ""
        } `}
        onClick={() => {
          setSearchParams({
            ...Object.fromEntries(searchParams),
            tab: number,
          });
        }}
      >
        <div className="listening_tab_text">{text}</div>
      </div>
    );
  };

  const necessaryRequest = () => {
    switch (searchParams.get("tab")) {
      case "1":
        dispatch(realVolumeThunk(searchData));
        dispatch(realVolumeKeysThunk());
        break;
      case "2":
        dispatch(marketCapThunk(searchData));
        dispatch(marketCapKeysThunk());
        break;
      case "3":
        dispatch(ListingHoldersConcentrationKeysThunk());
        dispatch(holdersConcentrationThunk(searchData));
        break;
      case "4":
        dispatch(ListingMaturityKeysThunk());
        dispatch(maturityThunk(searchData));
        break;
      case "5":
        dispatch(ListingSecurityKeysThunk());
        dispatch(securityThunk(searchData));
        break;
      case "6":
        dispatch(ListingGeneralKeysThunk());
        dispatch(generalThunk(searchData));
        break;
      case "7":
        dispatch(ListingRegulationKeysThunk());
        dispatch(regulationThunk(searchData));
        break;
    }
  };

  const activeValue = () => {
    if (searchParams.get("tab") === "1") {
      return realVolumeData;
    } else if (searchParams.get("tab") === "2") {
      return marketCapData;
    } else if (searchParams.get("tab") === "3") {
      return listingHoldersConcentrationValueData;
    } else if (searchParams.get("tab") === "4") {
      return listingMaturityValueData;
    } else if (searchParams.get("tab") === "5") {
      return listingSecurityValueData;
    } else if (searchParams.get("tab") === "7") {
      return listingRegulationValueData;
    } else if (searchParams.get("tab") === "6") {
      return listingGeneralValueData;
    }
  };

  return (
    <>
      <Header title={"Assets Insight"} />
      <main className={`listening_main_wrapper ${openMenu ? "open" : ""}`}>
        <div className="listening_tabs_main_wrapper">
          <div className="listening_tabs_wrapper">
            {generateTab("1", "Real Volume")}
            {generateTab("2", "Market Cap")}
            {generateTab("3", "Holders Concentration")}
            {generateTab("4", "Maturity")}
            {generateTab("5", "Security")}
            {generateTab("7", "Regulation")}
            {generateTab("6", "General")}
          </div>
        </div>
        <div className="listing_content_wrapper">
          <ListingSearchAndFilter
            setIsModalOpen={setIsModalOpen}
            setModalType={setModalType}
            getSearchData={(e) => setSearchData(e)}
            searchData={searchData}
          />
          <ListingTable
            tableData={activeValue()}
            setIsModalOpen={setIsModalOpen}
            setModalType={setModalType}
          />
        </div>
      </main>
      <ListingFilter
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        modalType={modalType}
        necessaryRequest={necessaryRequest}
      />
    </>
  );
}
