import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  ApiChangeUserInformation,
  ApiDeleteUserAvatar,
} from "../../../../../Api";
import { ProfileThunk } from "../../../../../Thunks";

import * as _ from "lodash";

import copy from "../../../../../Assets/img/icons/copy_white.svg";

import user from "../../../../../Assets/img/icons/user.jpg";

export function UserSettings() {
  let userNameUpdateToken = undefined;
  let userDescriptionToken = undefined;
  const fileInputRef = useRef(null);

  const [avatarImage, setAvatarImage] = useState(user);
  const [avatarActions, setAvatarActions] = useState(false);
  const [userName, setUserName] = useState("");
  const [userDescription, setUserDescription] = useState("");

  const dispatch = useDispatch();

  const profileData = useSelector(({ profile }) => {
    return profile.profileResult;
  });

  useEffect(() => {
    if (profileData && profileData?.description) {
      setUserDescription(profileData?.description);
    }
    if (profileData && profileData?.fullName) {
      setUserName(profileData.fullName);
    }
  }, [profileData]);

  const preventDragDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      const avatar = files[0];
      if (avatar) {
        const avatarBase64 = URL.createObjectURL(avatar);
        setAvatarImage(avatarBase64);

        ApiChangeUserInformation({ avatar })
          .then(() => {
            console.log("Avatar upload successful");
            dispatch(ProfileThunk());
          })
          .catch((error) => {
            console.error(
              "Error uploading avatar:",
              error?.response?.data?.error?.message
            );
          })
          .finally(() => {
            URL.revokeObjectURL(avatarBase64);
            console.log("Object URL revoked");
          });
      }
    } else {
      console.log("No files selected");
    }
  };
  const handleRemoveAvatar = () => {
    ApiDeleteUserAvatar()
      .then(() => {
        setAvatarImage(user);
        dispatch(ProfileThunk());
      })
      .catch((error) => {
        alert(error?.response?.data?.error?.message);
      });
  };
  return (
    <>
      <div className="user__info">
        <div className="user__info__avatar__block">
          <div
            className="user__info__avatar__wrapper"
            style={{
              position: "relative",
              zIndex: 100,
              overflow: "hidden",
              backgroundImage: profileData?.avatar
                ? `url(${profileData?.avatar})`
                : `url(${avatarImage})`,
              backgroundSize: "cover",
            }}
          >
            {
              <div
                className="avatarActions"
                style={{
                  backgroundColor: "rgba(105, 104, 111, 0.82)",
                  width: "100%",
                  height: "100%",
                  zIndex: 1,
                  position: "relative",
                }}
              >
                <form>
                  <label className="upload__content" htmlFor="img">
                    <img
                      src={copy}
                      className="uploda__user__image"
                      alt="Upload"
                    />
                    <span className="upload_title">Upload Image</span>
                    <input
                      type="file"
                      id="img"
                      name="img"
                      accept="image/*"
                      className="w-100"
                      ref={fileInputRef}
                      onChange={handleFileChange}
                      onContextMenu={(e) => e.preventDefault()}
                      onDragOver={preventDragDrop}
                      onDrop={preventDragDrop}
                    />
                  </label>
                </form>

                <span
                  className="deleteArea"
                  htmlFor="img"
                  onClick={handleRemoveAvatar}
                >
                  <i
                    className="icon-close uploda__user__image"
                    style={{ width: 16, height: 16 }}
                    title="Delete Image"
                  ></i>
                  <span className="upload_title">Remove Image</span>
                </span>
              </div>
            }
          </div>
        </div>

        <div className="user__info__desc__block">
          <input
            className="user__info__name"
            name="fullName"
            value={userName}
            onBlur={() => {
              ApiChangeUserInformation(
                {
                  fullName: userName,
                },
                userNameUpdateToken
              )
                .then(() => {
                  dispatch(ProfileThunk());
                })
                .catch((error) => {
                  alert(error?.response?.data?.error?.message);
                });
            }}
            onChange={(e) => {
              setUserName(e.target.value);
            }}
            onDragOver={preventDragDrop}
            onDrop={preventDragDrop}
          />
          <div className="user__info__desc">
            <div className="user__info__desc__text">
              <textarea
                className="user__info__desc__text"
                placeholder="Your info"
                onBlur={() => {
                  ApiChangeUserInformation(
                    {
                      description: userDescription || "",
                    },
                    userDescriptionToken
                  )
                    .then(() => {
                      dispatch(ProfileThunk());
                    })
                    .catch((error) => {
                      alert(error?.response?.data?.error?.message);
                    });
                }}
                value={userDescription}
                onChange={(event) => {
                  setUserDescription(event?.target?.value);
                }}
                onDragOver={preventDragDrop}
                onDrop={preventDragDrop}
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
