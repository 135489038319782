import Modal from "react-modal";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  getIsAssetsSelectsUpdated,
  getIsRealVolumeSelectsUpdated,
  getListingGeneralKeyData,
  getListingHoldersConcentrationKeyData,
  getListingHoldersConcentrationUpdatedKeys,
  getListingMarketCapKeys,
  getListingMarketCapUpdatedKeys,
  getListingMaturityKeyData,
  getListingRealVolumeKeys,
  getListingRegulationKeyData,
  getListingSecurityKeyData,
  getSelectedAssets,
  getlistingGeneralUpdatedKeys,
  getlistingMaturityUpdatedKeys,
  getlistingRegulationUpdatedKeys,
  getlistingSecurityUpdatedKeys,
} from "../../Reducer/ListeningReducer";
import {
  AssetsSelectThunk,
  ListingAssetsThunk,
  getSelectedAssetsThunk,
} from "../../Thunks";
import {
  generalKeyUpdateThunk,
  holdersConcentrationKeyUpdateThunk,
  marketCapKeysUpdateThunk,
  maturityKeyUpdateThunk,
  realVolumeKeysUpdateThunk,
  RegulationKeyUpdateThunk,
  securityKeyUpdateThunk,
} from "../../Thunks/ListeningThunk";

export const ListingFilter = ({
  isModalOpen,
  setIsModalOpen,
  modalType,
  necessaryRequest,
}) => {
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();

  const assets = useSelector(getSelectedAssets);
  const assetsSelectsUpdated = useSelector(getIsAssetsSelectsUpdated);
  const realVolumeKeys = useSelector(getListingRealVolumeKeys);
  const realVolumeKeysUpdated = useSelector(getIsRealVolumeSelectsUpdated);
  const marketCapKeys = useSelector(getListingMarketCapKeys);
  const marketCapKeysUpdated = useSelector(getListingMarketCapUpdatedKeys);

  const listingHoldersConcentrationKeys = useSelector(
    getListingHoldersConcentrationKeyData
  );
  const listingHoldersConcentrationKeysUpdated = useSelector(
    getListingHoldersConcentrationUpdatedKeys
  );
  const listingMaturityKeys = useSelector(getListingMaturityKeyData);
  const listingMaturityKeysUpdated = useSelector(getlistingMaturityUpdatedKeys);

  const listingSecurityKeys = useSelector(getListingSecurityKeyData);
  const listingSecurityKeysUpdated = useSelector(getlistingSecurityUpdatedKeys);

  const listingGeneralKeys = useSelector(getListingGeneralKeyData);
  const listingGeneralKeysUpdated = useSelector(getlistingGeneralUpdatedKeys);

  const listingRegulationKeys = useSelector(getListingRegulationKeyData);
  const listingRegulationKeysUpdated = useSelector(
    getlistingRegulationUpdatedKeys
  );

  const [assetsList, setAssetsList] = useState([]);
  const [realVolumeList, setRealVolumeList] = useState([]);
  const [marketCapList, setMarketCapList] = useState([]);

  const [holdersConcentrationList, setHoldersConcentrationList] = useState([]);
  const [maturityList, setMaturityList] = useState([]);

  const [securityList, setSecurityList] = useState([]);
  const [generalList, setGeneralList] = useState([]);

  const [regulationList, setRegulationList] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    dispatch(getSelectedAssetsThunk());
  }, [dispatch]);
  useEffect(() => {
    if (
      assetsSelectsUpdated ||
      realVolumeKeysUpdated ||
      marketCapKeysUpdated ||
      listingHoldersConcentrationKeysUpdated ||
      listingMaturityKeysUpdated ||
      listingSecurityKeysUpdated ||
      listingGeneralKeysUpdated ||
      listingRegulationKeysUpdated
    ) {
      dispatch(getSelectedAssetsThunk());
      setIsModalOpen(false);
      necessaryRequest();
    }
  }, [
    assetsSelectsUpdated,
    realVolumeKeysUpdated,
    marketCapKeysUpdated,
    listingMaturityKeysUpdated,
    listingHoldersConcentrationKeysUpdated,
    listingSecurityKeysUpdated,
    listingGeneralKeysUpdated,
    listingRegulationKeysUpdated,

    dispatch,
  ]);

  useEffect(() => {
    if (assets) {
      setAssetsList(
        assets.map(({ assetName, id, listingAssetsUserInfo }) => ({
          assetName,
          id,
          selected: listingAssetsUserInfo.selected,
          orderBy: listingAssetsUserInfo.orderBy,
        }))
      );
    }
  }, [assets, isModalOpen]);

  useEffect(() => {
    if (realVolumeKeys) {
      setRealVolumeList(
        realVolumeKeys.map(({ name, id, selected }) => ({
          name,
          id,
          selected,
        }))
      );
    }
  }, [realVolumeKeys, isModalOpen]);

  useEffect(() => {
    if (marketCapKeys) {
      setMarketCapList(
        marketCapKeys.map(({ name, id, selected }) => ({
          name,
          id,
          selected,
        }))
      );
    }
  }, [marketCapKeys, isModalOpen]);

  useEffect(() => {
    if (listingHoldersConcentrationKeys) {
      setHoldersConcentrationList(
        listingHoldersConcentrationKeys.map(({ name, id, selected }) => ({
          name,
          id,
          selected,
        }))
      );
    }
  }, [listingHoldersConcentrationKeys, isModalOpen]);

  useEffect(() => {
    if (listingMaturityKeys) {
      setMaturityList(
        listingMaturityKeys.map(({ name, id, selected }) => ({
          name,
          id,
          selected,
        }))
      );
    }
  }, [listingMaturityKeys, isModalOpen]);

  useEffect(() => {
    if (listingSecurityKeys) {
      setSecurityList(
        listingSecurityKeys.map(({ name, id, selected }) => ({
          name,
          id,
          selected,
        }))
      );
    }
  }, [listingSecurityKeys, isModalOpen]);

  useEffect(() => {
    if (listingGeneralKeys) {
      setGeneralList(
        listingGeneralKeys.map(({ name, id, selected }) => ({
          name,
          id,
          selected,
        }))
      );
    }
  }, [listingGeneralKeys, isModalOpen]);

  useEffect(
    () => {
      if (listingRegulationKeys) {
        setGeneralList(
          listingRegulationKeys.map(({ name, id, selected }) => ({
            name,
            id,
            selected,
          }))
        );
      }
    },
    [listingRegulationKeys],
    isModalOpen
  );

  useEffect(() => {
    dispatch(ListingAssetsThunk());
  }, [searchParams.get("tab")]);

  const isAllAssetsSelected = () =>
    assetsList.every(({ selected }) => selected);

  const isAllRealVolumeKeysSelected = () =>
    realVolumeList.every(({ selected }) => selected);
  const isAllMarketCapKeysSelected = () =>
    marketCapList.every(({ selected }) => selected);

  const isAllholdersConcentrationKeysSelected = () =>
    holdersConcentrationList.every(({ selected }) => selected);

  const isAllMaturityKeysSelected = () =>
    maturityList.every(({ selected }) => selected);

  const isAllSecurityKeysSelected = () =>
    securityList.every(({ selected }) => selected);

  const isAllGeneralKeysSelected = () =>
    generalList.every(({ selected }) => selected);

  const isAllRegulationKeysSelected = () =>
    regulationList.every(({ selected }) => selected);

  const customStyles = {
    content: {
      height: "686px",
      width: modalType === "customizeAssets" ? "397px" : "637px",
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      right: "auto",
      bottom: "auto",
      border: "none",
      backgroundColor: "white",
      borderRadius: "24px",
      padding: "26px 0 35px 40px",
    },
  };

  const changeAssetsSelects = (isAll, id) => {
    if (isAll && isAllAssetsSelected()) {
      const assetsUpdatedSelect = assetsList?.map((item) => {
        return {
          ...item,
          selected: false,
        };
      });
      setAssetsList(assetsUpdatedSelect);
    } else if (isAll && !isAllAssetsSelected()) {
      const assetsUpdatedSelect = assetsList?.map((item) => {
        return {
          ...item,
          selected: true,
        };
      });
      setAssetsList(assetsUpdatedSelect);
    } else if (!isAll && id) {
      const assetsUpdatedSelect = assetsList?.map((item) => {
        if (item.id !== id) {
          return item;
        } else {
          return {
            ...item,
            selected: !item.selected,
          };
        }
      });
      setAssetsList(assetsUpdatedSelect);
    }
  };

  const changeRealVolumeKeysSelects = (isAll, id) => {
    if (isAll && isAllRealVolumeKeysSelected()) {
      const realVolumeKeysSelect = realVolumeList?.map((item) => {
        return {
          ...item,
          selected: false,
        };
      });
      setRealVolumeList(realVolumeKeysSelect);
    } else if (isAll && !isAllRealVolumeKeysSelected()) {
      const realVolumeKeysSelect = realVolumeList?.map((item) => {
        return {
          ...item,
          selected: true,
        };
      });
      setRealVolumeList(realVolumeKeysSelect);
    } else if (!isAll && id) {
      const realVolumeKeysSelect = realVolumeList?.map((item) => {
        if (item.id !== id) {
          return item;
        } else {
          return {
            ...item,
            selected: !item.selected,
          };
        }
      });
      setRealVolumeList(realVolumeKeysSelect);
    }
  };

  const changeMarketCapKeysSelects = (isAll, id) => {
    if (isAll && isAllMarketCapKeysSelected()) {
      const marketCapKeysSelect = marketCapList?.map((item) => {
        return {
          ...item,
          selected: false,
        };
      });
      setMarketCapList(marketCapKeysSelect);
    } else if (isAll && !isAllMarketCapKeysSelected()) {
      const marketCapKeysSelect = marketCapList?.map((item) => {
        return {
          ...item,
          selected: true,
        };
      });
      setMarketCapList(marketCapKeysSelect);
    } else if (!isAll && id) {
      const marketCapKeysSelect = marketCapList?.map((item) => {
        if (item.id !== id) {
          return item;
        } else {
          return {
            ...item,
            selected: !item.selected,
          };
        }
      });
      setMarketCapList(marketCapKeysSelect);
    }
  };

  const changeHoldersConcentrationKeysSelects = (isAll, id) => {
    if (isAll && isAllholdersConcentrationKeysSelected()) {
      const holdersConcentrationKeysSelect = holdersConcentrationList?.map(
        (item) => {
          return {
            ...item,
            selected: false,
          };
        }
      );
      setHoldersConcentrationList(holdersConcentrationKeysSelect);
    } else if (isAll && !isAllholdersConcentrationKeysSelected()) {
      const holdersConcentrationKeysSelect = holdersConcentrationList?.map(
        (item) => {
          return {
            ...item,
            selected: true,
          };
        }
      );
      setHoldersConcentrationList(holdersConcentrationKeysSelect);
    } else if (!isAll && id) {
      const holdersConcentrationKeysSelect = holdersConcentrationList?.map(
        (item) => {
          if (item.id !== id) {
            return item;
          } else {
            return {
              ...item,
              selected: !item.selected,
            };
          }
        }
      );
      setHoldersConcentrationList(holdersConcentrationKeysSelect);
    }
  };

  const changeMaturityKeysSelects = (isAll, id) => {
    if (isAll && isAllMaturityKeysSelected()) {
      const maturityKeysSelect = maturityList?.map((item) => {
        return {
          ...item,
          selected: false,
        };
      });
      setMaturityList(maturityKeysSelect);
    } else if (isAll && !isAllMaturityKeysSelected()) {
      const maturityKeysSelect = maturityList?.map((item) => {
        return {
          ...item,
          selected: true,
        };
      });
      setMaturityList(maturityKeysSelect);
    } else if (!isAll && id) {
      const maturityKeysSelect = maturityList?.map((item) => {
        if (item.id !== id) {
          return item;
        } else {
          return {
            ...item,
            selected: !item.selected,
          };
        }
      });
      setMaturityList(maturityKeysSelect);
    }
  };

  const changeSecurityKeysSelects = (isAll, id) => {
    if (isAll && isAllSecurityKeysSelected()) {
      const securityKeysSelect = securityList?.map((item) => {
        return {
          ...item,
          selected: false,
        };
      });
      setSecurityList(securityKeysSelect);
    } else if (isAll && !isAllSecurityKeysSelected()) {
      const securityKeysSelect = securityList?.map((item) => {
        return {
          ...item,
          selected: true,
        };
      });
      setSecurityList(securityKeysSelect);
    } else if (!isAll && id) {
      const securityKeysSelect = securityList?.map((item) => {
        if (item.id !== id) {
          return item;
        } else {
          return {
            ...item,
            selected: !item.selected,
          };
        }
      });
      setSecurityList(securityKeysSelect);
    }
  };

  const changeGeneralKeysSelects = (isAll, id) => {
    if (isAll && isAllGeneralKeysSelected()) {
      const generalKeysSelect = generalList?.map((item) => {
        return {
          ...item,
          selected: false,
        };
      });
      setGeneralList(generalKeysSelect);
    } else if (isAll && !isAllGeneralKeysSelected()) {
      const generalKeysSelect = generalList?.map((item) => {
        return {
          ...item,
          selected: true,
        };
      });
      setGeneralList(generalKeysSelect);
    } else if (!isAll && id) {
      const generalKeysSelect = generalList?.map((item) => {
        if (item.id !== id) {
          return item;
        } else {
          return {
            ...item,
            selected: !item.selected,
          };
        }
      });
      setGeneralList(generalKeysSelect);
    }
  };

  const changeRegulationKeysSelects = (isAll, id) => {
    if (isAll && isAllRegulationKeysSelected()) {
      const regulationKeysSelect = regulationList?.map((item) => {
        return {
          ...item,
          selected: false,
        };
      });
      setRegulationList(regulationKeysSelect);
    } else if (isAll && !isAllRegulationKeysSelected()) {
      const regulationKeysSelect = regulationList?.map((item) => {
        return {
          ...item,
          selected: true,
        };
      });
      setRegulationList(regulationKeysSelect);
    } else if (!isAll && id) {
      const regulationKeysSelect = regulationList?.map((item) => {
        if (item.id !== id) {
          return item;
        } else {
          return {
            ...item,
            selected: !item.selected,
          };
        }
      });
      setRegulationList(regulationKeysSelect);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const createSelectItem = (id, selected, name, changeForm) => {
    return (
      <>
        <div
          onClick={() => changeForm(false, id)}
          className="listing_single_volume_wrapper"
        >
          <input
            type="checkbox"
            checked={selected}
            className="listing_single_volume_checkbox"
          />
          <h3>{name}</h3>
        </div>
        <hr className="listing_volumes_divider" />
      </>
    );
  };

  const createModalContent = () => {
    if (modalType === "customizeAssets") {
      return assetsList
        ?.filter(({ assetName }) => {
          if (!searchTerm) return true;

          const searchTerms = searchTerm
            .toLowerCase()
            .split(",")
            .map((term) => term.trim()); // Split by comma and trim spaces

          return searchTerms.some((term) =>
            assetName.toLowerCase().includes(term)
          );
        })
        .map(({ assetName, id, selected }) =>
          createSelectItem(id, selected, assetName, changeAssetsSelects)
        );
    } else if (modalType === "assets") {
      if (searchParams.get("tab") === "1") {
        return realVolumeList?.map(({ name, id, selected }) => {
          return createSelectItem(
            id,
            selected,
            name,
            changeRealVolumeKeysSelects
          );
        });
      } else if (searchParams.get("tab") === "2") {
        return marketCapList?.map(({ name, id, selected }) => {
          return createSelectItem(
            id,
            selected,
            name,
            changeMarketCapKeysSelects
          );
        });
      } else if (searchParams.get("tab") === "3") {
        return holdersConcentrationList?.map(({ name, id, selected }) => {
          return createSelectItem(
            id,
            selected,
            name,
            changeHoldersConcentrationKeysSelects
          );
        });
      } else if (searchParams.get("tab") === "4") {
        return maturityList?.map(({ name, id, selected }) => {
          return createSelectItem(
            id,
            selected,
            name,
            changeMaturityKeysSelects
          );
        });
      } else if (searchParams.get("tab") === "5") {
        return securityList?.map(({ name, id, selected }) => {
          return createSelectItem(
            id,
            selected,
            name,
            changeSecurityKeysSelects
          );
        });
      } else if (searchParams.get("tab") === "6") {
        return generalList?.map(({ name, id, selected }) => {
          return createSelectItem(id, selected, name, changeGeneralKeysSelects);
        });
      } else if (searchParams.get("tab") === "7") {
        return regulationList?.map(({ name, id, selected }) => {
          return createSelectItem(
            id,
            selected,
            name,
            changeRegulationKeysSelects
          );
        });
      }
    }
  };

  const addAllClick = () => {
    if (modalType === "customizeAssets") {
      changeAssetsSelects(true);
    } else if (modalType === "assets") {
      if (searchParams.get("tab") === "1") {
        changeRealVolumeKeysSelects(true);
      } else if (searchParams.get("tab") === "2") {
        changeMarketCapKeysSelects(true);
      } else if (searchParams.get("tab") === "3") {
        changeHoldersConcentrationKeysSelects(true);
      } else if (searchParams.get("tab") === "4") {
        changeMaturityKeysSelects(true);
      } else if (searchParams.get("tab") === "5") {
        changeSecurityKeysSelects(true);
      } else if (searchParams.get("tab") === "6") {
        changeGeneralKeysSelects(true);
      } else if (searchParams.get("tab") === "7") {
        changeRegulationKeysSelects(true);
      }
    }
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={() => setIsModalOpen(false)}
      style={customStyles}
    >
      <div className="listing_modal_head">
        <h2 className="listing_modal_title">
          {/* {searchParams.get("tab") === "0"
            ? "Real Volume"
            : "Parameters to present"} */}
          {modalType == "customizeAssets"
            ? "Assets to display"
            : "Parameters to present"}
        </h2>
        <i
          className="icon-close listing_modal_close_icon"
          onClick={() => {
            setIsModalOpen(false);
            setSearchTerm("");
          }}
        ></i>
      </div>
      <div className="listening_search_wrapper assets_search_wrapper">
        <div className="listening_search_icon_wrapper" />
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          className="listening_search_field "
          placeholder="Search..."
        />
      </div>
      <div className="listing_volumes_wrapper">
        <div onClick={addAllClick} className="listing_single_volume_wrapper">
          <input
            type="checkbox"
            checked={
              modalType === "customizeAssets"
                ? isAllAssetsSelected()
                : searchParams.get("tab") === "1"
                ? isAllRealVolumeKeysSelected()
                : searchParams.get("tab") === "2"
                ? isAllMarketCapKeysSelected()
                : searchParams.get("tab") === "3"
                ? isAllholdersConcentrationKeysSelected()
                : searchParams.get("tab") === "4"
                ? isAllMaturityKeysSelected()
                : searchParams.get("tab") === "5"
                ? isAllSecurityKeysSelected()
                : searchParams.get("tab") === "6"
                ? isAllGeneralKeysSelected()
                : isAllRegulationKeysSelected()
            }
            className="listing_single_volume_checkbox"
          />
          <h3>Add All</h3>
        </div>
        <hr className="listing_volumes_divider" />
        {createModalContent()}
      </div>
      <div className="listing_modal_submit_wrapper">
        <button
          onClick={() => {
            if (modalType === "customizeAssets") {
              dispatch(AssetsSelectThunk(assetsList));
            } else if (modalType === "assets") {
              if (searchParams.get("tab") === "1") {
                dispatch(realVolumeKeysUpdateThunk(realVolumeList));
              } else if (searchParams.get("tab") === "2") {
                dispatch(marketCapKeysUpdateThunk(marketCapList));
              } else if (searchParams.get("tab") === "3") {
                dispatch(
                  holdersConcentrationKeyUpdateThunk(holdersConcentrationList)
                );
              } else if (searchParams.get("tab") === "4") {
                dispatch(maturityKeyUpdateThunk(maturityList));
              } else if (searchParams.get("tab") === "5") {
                dispatch(securityKeyUpdateThunk(securityList));
              } else if (searchParams.get("tab") === "6") {
                dispatch(generalKeyUpdateThunk(generalList));
                console.log(6);
              } else if (searchParams.get("tab") === "7") {
                dispatch(RegulationKeyUpdateThunk(regulationList));
              }
            }
          }}
          className="listing_modal_submit"
        >
          ACCEPT
        </button>
      </div>
    </Modal>
  );
};
