import { relativeTimeRounding } from "moment/moment";

export const REAL_VOLUME_DATA = "REAL_VOLUME_DATA";
export const MARKET_CAP_DATA = "MARKET_CAP_DATA";
export const HOLDERS_CONCENTRATION_DATA = "HOLDERS_CONCENTRATION";
export const MATURITY_DATA = "MATURITY_CAP_DATA";
export const SECURITY_DATA = "SECURITY_CAP_DATA";
export const GENERAL_DATA = "GENERAL_CAP_DATA";
export const REGULATION_DATA = "REGULATION_DATA";
export const ASSETS_DATA = "ASSETS_DATA";
export const GET_SELECTED_ASSETS = "GET_SELECTED_ASSETS";
export const SELECT_ASSETS_DATA = "SELECT_ASSETS_DATA";
export const ASSETS_SELECTS_CHANGED = "ASSETS_SELECTS_CHANGED";
export const REAL_VOLUME_KEYS = "REAL_VOLUME_KEYS";
export const REAL_VOLUME_KEYS_UPDATED = "REAL_VOLUME_KEYS_UPDATED";
export const MARKET_CAP_KEYS = "MARKET_CAP_KEYS";
export const MARKET_CAP_KEYS_UPDATED = "MARKET_CAP_KEYS_UPDATED";
export const LISTING_HOLDERS_CONCENTRATION_KEYS_REQUEST =
  "LISTING_HOLDERS_CONCENTRATION_KEYS_REQUEST";
export const LISTING_HOLDERS_CONCENTRATION_KEYS_SUCCESS =
  "LISTING_HOLDERS_CONCENTRATION_KEYS_SUCCESS";
export const LISTING_HOLDERS_CONCENTRATION_KEYS_FAIL =
  "LISTING_HOLDERS_CONCENTRATION_KEYS_FAIL";
export const LISTING_HOLDERS_CONCENTRATION_VALUE_REQUEST =
  "LISTING_HOLDERS_CONCENTRATION_VALUE_REQUEST";
export const LISTING_HOLDERS_CONCENTRATION_VALUE_SUCCESS =
  "LISTING_HOLDERS_CONCENTRATION_VALUE_SUCCESS";
export const LISTING_HOLDERS_CONCENTRATION_VALUE_FAIL =
  "LISTING_HOLDERS_CONCENTRATION_VALUE_FAIL";
export const LISTING_HOLDERS_CONCENTRATION_KEYS_UPDATED =
  "LISTING_HOLDERS_CONCENTRATION_KEYS_UPDATED";

export const LISTING_MATURITY_KEYS_REQUEST = "LISTING_MATURITY_KEYS_REQUEST";
export const LISTING_MATURITY_KEYS_SUCCESS = "LISTING_MATURITY_KEYS_SUCCESS";
export const LISTING_MATURITY_KEYS_FAIL = "LISTING_MATURITY_KEYS_FAIL";
export const LISTING_MATURITY_VALUE_REQUEST = "LISTING_MATURITY_VALUE_REQUEST";
export const LISTING_MATURITY_VALUE_SUCCESS = "LISTING_MATURITY_VALUE_SUCCESS";
export const LISTING_MATURITY_VALUE_FAIL = "LISTING_MATURITY_VALUE_FAIL";
export const LISTING_MATURITY_KEYS_UPDATED = "LISTING_MATURITY";

export const LISTING_SECURITY_KEYS_REQUEST = "LISTING_SECURITY_REQUEST";
export const LISTING_SECURITY_KEYS_SUCCESS = "LISTING_SECURITY_KEYS_SUCCESS";
export const LISTING_SECURITY_KEYS_FAIL = "LISTING_SECURITY_FAIL";
export const LISTING_SECURITY_VALUE_REQUEST = "LISTING_SECURITY_REQUEST";
export const LISTING_SECURITY_VALUE_SUCCESS = "LISTING_SECURITY_VALUE_SUCCESS";
export const LISTING_SECURITY_VALUE_FAIL = "LISTING_SECURITY_FAIL";
export const LISTING_SECURITY_KEYS_UPDATED = "LISTING_SECURITY";

export const LISTING_GENERAL_KEYS_REQUEST = "LISTING_GENERAL_REQUEST";
export const LISTING_GENERAL_KEYS_SUCCESS = "LISTING_GENERAL_KEYS_SUCCESS";
export const LISTING_GENERAL_KEYS_FAIL = "LISTING_GENERAL_FAIL";
export const LISTING_GENERAL_VALUE_REQUEST = "LISTING_GENERAL_REQUEST";
export const LISTING_GENERAL_VALUE_SUCCESS = "LISTING_GENERAL_VALUE_SUCCESS";
export const LISTING_GENERAL_VALUE_FAIL = "LISTING_GENERAL_FAIL";
export const LISTING_GENERAL_KEYS_UPDATED = "LISTING_GENERAL";

export const LISTING_REGULATION_KEYS_REQUEST = "LISTING_REGULATION_REQUEST";
export const LISTING_REGULATION_KEYS_SUCCESS =
  "LISTING_REGULATION_KEYS_SUCCESS";
export const LISTING_REGULATION_KEYS_FAIL = "LISTING_REGULATION_FAIL";
export const LISTING_REGULATION_VALUE_REQUEST = "LISTING_REGULATION_REQUEST";
export const LISTING_REGULATION_VALUE_SUCCESS =
  "LISTING_REGULATION_VALUE_SUCCESS";
export const LISTING_REGULATION_VALUE_FAIL = "LISTING_REGULATION_FAIL";
export const LISTING_REGULATION_KEYS_UPDATED = "LISTING_REGULATION";

export const SAVE_AS_EXCEL = "SAVE_AS_EXCEL";

const loginState = {
  error: null,
  loading: false,
  success: false,
  realVolumeData: [],
  marketCapData: [],
  assets: [],
  selectedAssets: [],
  assetsSelectsUpdated: false,
  realVolumeKeys: [],
  realVolumeKeysSelectsUpdated: false,
  marketCapKeys: [],
  marketCapKeysSelectsUpdated: false,

  ListingHoldersConcentrationKeyData: [],
  ListingHoldersConcentrationKeyLoading: false,
  ListingHoldersConcentrationKeyError: null,
  ListingHoldersConcentrationKeyUpdated: false,

  ListingHoldersConcentrationValueData: [],
  ListingHoldersConcentrationValueLoading: false,
  ListingHoldersConcentrationValueError: null,

  ListingMaturityKeyData: [],
  ListingMaturityKeyLoading: false,
  ListingMaturityKeyError: null,
  listingMaturityKeyUpdated: false,

  ListingMaturityValueData: [],
  ListingMaturityValueLoading: false,
  ListingMaturityValueError: null,

  ListingSecurityKeyData: [],
  ListingSecurityKeyLoading: false,
  ListingSecurityKeyError: null,
  listingSecurityKeyUpdated: false,

  ListingSecurityValueData: [],
  ListingSecurityValueLoading: false,
  ListingSecurityValueError: null,

  ListingGeneralKeyData: [],
  ListingGeneralKeyLoading: false,
  ListingGeneralKeyError: null,
  listingGeneralKeyUpdated: false,

  ListingGeneralValueData: [],
  ListingGeneralValueLoading: false,
  ListingGeneralValueError: null,

  ListingRegulationKeyData: [],
  ListingRegulationKeyLoading: false,
  ListingRegulationKeyError: null,
  listingRegulationKeyUpdated: false,

  ListingRegulationValueData: [],
  ListingRegulationValueLoading: false,
  ListingRegulationValueError: null,

  exportData: "",
};

export function ListingReducer(state = loginState, action) {
  if (action.type === REAL_VOLUME_DATA) {
    return {
      ...state,
      realVolumeData: action.payload.data,
      assetsSelectsUpdated: false,
      realVolumeKeysSelectsUpdated: false,
      marketCapKeysSelectsUpdated: false,
      ListingHoldersConcentrationKeyUpdated: false,
      listingMaturityKeyUpdated: false,
      listingSecurityKeyUpdated: false,
      listingGeneralKeyUpdated: false,
      listingRegulationKeyUpdated: false,
    };
  } else if (action.type === MARKET_CAP_DATA) {
    return {
      ...state,
      marketCapData: action.payload.data,
      assetsSelectsUpdated: false,
      realVolumeKeysSelectsUpdated: false,
      marketCapKeysSelectsUpdated: false,
      ListingHoldersConcentrationKeyUpdated: false,
      listingMaturityKeyUpdated: false,
      listingGeneralKeyUpdated: false,
      listingRegulationKeyUpdated: false,
    };
  } else if (action.type === HOLDERS_CONCENTRATION_DATA) {
    return {
      ...state,
      ListingHoldersConcentrationValueData: action.payload.data,
      assetsSelectsUpdated: false,
      realVolumeKeysSelectsUpdated: false,
      marketCapKeysSelectsUpdated: false,
      ListingHoldersConcentrationKeyUpdated: false,
      listingMaturityKeyUpdated: false,
      listingGeneralKeyUpdated: false,
      listingRegulationKeyUpdated: false,
    };
  } else if (action.type === MATURITY_DATA) {
    return {
      ...state,
      ListingMaturityValueData: action.payload.data,
      assetsSelectsUpdated: false,
      realVolumeKeysSelectsUpdated: false,
      marketCapKeysSelectsUpdated: false,
      ListingHoldersConcentrationKeyUpdated: false,
      listingMaturityKeyUpdated: false,
      listingSecurityKeyUpdated: false,
      listingGeneralKeyUpdated: false,
      listingRegulationKeyUpdated: false,
    };
  } else if (action.type === SECURITY_DATA) {
    return {
      ...state,
      ListingSecurityValueData: action.payload.data,
      assetsSelectsUpdated: false,
      realVolumeKeysSelectsUpdated: false,
      marketCapKeysSelectsUpdated: false,
      ListingHoldersConcentrationKeyUpdated: false,
      listingMaturityKeyUpdated: false,
      listingGeneralKeyUpdated: false,
      listingRegulationKeyUpdated: false,
    };
  } else if (action.type === GENERAL_DATA) {
    return {
      ...state,
      ListingGeneralValueData: action.payload.data,
      assetsSelectsUpdated: false,
      realVolumeKeysSelectsUpdated: false,
      marketCapKeysSelectsUpdated: false,
      ListingHoldersConcentrationKeyUpdated: false,
      listingMaturityKeyUpdated: false,
      listingSecurityKeyUpdated: false,
      listingGeneralKeyUpdated: false,
      listingRegulationKeyUpdated: false,
    };
  } else if (action.type === REGULATION_DATA) {
    return {
      ...state,
      ListingRegulationValueData: action.payload.data,
      assetsSelectsUpdated: false,
      realVolumeKeysSelectsUpdated: false,
      marketCapKeysSelectsUpdated: false,
      ListingHoldersConcentrationKeyUpdated: false,
      listingMaturityKeyUpdated: false,
      listingGeneralKeyUpdated: false,
      listingSecurityKeyUpdated: false,
      listingRegulationKeyUpdated: false,
    };
  } else if (action.type === ASSETS_DATA) {
    return {
      ...state,
      assets: action.payload.data,
    };
  } else if (action.type === SELECT_ASSETS_DATA) {
    return {
      ...state,
      selectedAssets: action.payload.data,
    };
  } else if (action.type === ASSETS_SELECTS_CHANGED) {
    return {
      ...state,
      assetsSelectsUpdated: true,
    };
  } else if (action.type === REAL_VOLUME_KEYS) {
    return {
      ...state,
      realVolumeKeys: action.payload.data,
    };
  } else if (action.type === REAL_VOLUME_KEYS_UPDATED) {
    return {
      ...state,
      realVolumeKeysSelectsUpdated: true,
    };
  } else if (action.type === MARKET_CAP_KEYS) {
    return {
      ...state,
      marketCapKeys: action.payload.data,
    };
  } else if (action.type === MARKET_CAP_KEYS_UPDATED) {
    return {
      ...state,
      marketCapKeysSelectsUpdated: true,
    };
  } else if (action.type === LISTING_HOLDERS_CONCENTRATION_KEYS_REQUEST) {
    return {
      ...state,
      ListingHoldersConcentrationKeyLoading: true,
    };
  } else if (action.type === LISTING_HOLDERS_CONCENTRATION_KEYS_SUCCESS) {
    return {
      ...state,
      ListingHoldersConcentrationKeyLoading: false,
      ListingHoldersConcentrationKeyData: action.payload,
    };
  } else if (action.type === LISTING_HOLDERS_CONCENTRATION_KEYS_FAIL) {
    return {
      ...state,
      ListingHoldersConcentrationKeyLoading: false,
      ListingHoldersConcentrationKeyError: action.payload,
    };
  } else if (action.type === LISTING_HOLDERS_CONCENTRATION_VALUE_REQUEST) {
    return {
      ...state,
      ListingHoldersConcentrationValueLoading: true,
    };
  } else if (action.type === LISTING_HOLDERS_CONCENTRATION_VALUE_SUCCESS) {
    return {
      ...state,
      ListingHoldersConcentrationValueLoading: false,
      ListingHoldersConcentrationValueData: action.payload,
      assetsSelectsUpdated: false,
      realVolumeKeysSelectsUpdated: false,
      marketCapKeysSelectsUpdated: false,
      ListingHoldersConcentrationKeyUpdated: false,
      listingMaturityKeyUpdated: false,
    };
  } else if (action.type === LISTING_HOLDERS_CONCENTRATION_VALUE_FAIL) {
    return {
      ...state,
      ListingHoldersConcentrationValueLoading: false,
      ListingHoldersConcentrationValueError: action.payload,
    };
  } else if (action.type === LISTING_HOLDERS_CONCENTRATION_KEYS_UPDATED) {
    return {
      ...state,
      ListingHoldersConcentrationKeyUpdated: true,
    };
  } else if (action.type === LISTING_MATURITY_KEYS_REQUEST) {
    return {
      ...state,
      ListingMaturityKeyLoading: true,
    };
  } else if (action.type === LISTING_MATURITY_KEYS_SUCCESS) {
    return {
      ...state,
      ListingMaturityKeyLoading: false,
      ListingMaturityKeyData: action.payload,
      // assetsSelectsUpdated: false,
      // realVolumeKeysSelectsUpdated: false,
      // marketCapKeysSelectsUpdated: false,
      // ListingHoldersConcentrationKeyUpdated: false,
      // listingMaturityKeyUpdated: false,
    };
  } else if (action.type === LISTING_MATURITY_KEYS_FAIL) {
    return {
      ...state,
      ListingMaturityKeyLoading: false,
      ListingMaturityKeyError: action.payload,
    };
  } else if (action.type === LISTING_MATURITY_VALUE_REQUEST) {
    return {
      ...state,
      ListingMaturityValueLoading: true,
    };
  } else if (action.type === LISTING_MATURITY_VALUE_SUCCESS) {
    return {
      ...state,
      ListingMaturityValueLoading: false,
      ListingMaturityValueData: action.payload,
      assetsSelectsUpdated: false,
      realVolumeKeysSelectsUpdated: false,
      marketCapKeysSelectsUpdated: false,
      ListingHoldersConcentrationKeyUpdated: false,
      listingMaturityKeyUpdated: false,
      listingRegulationKeyUpdated: false,
      listingGeneralKeyUpdated: false,
      listingSecurityKeyUpdated: false,
    };
  } else if (action.type === LISTING_MATURITY_VALUE_FAIL) {
    return {
      ...state,
      ListingMaturityValueLoading: false,
      ListingMaturityValueError: action.payload,
    };
  } else if (action.type === LISTING_MATURITY_KEYS_UPDATED) {
    return {
      ...state,
      listingMaturityKeyUpdated: true,
    };
  } else if (action.type === LISTING_SECURITY_KEYS_REQUEST) {
    /*Security */
    return {
      ...state,
      ListingMaturityKeyLoading: true,
    };
  } else if (action.type === LISTING_SECURITY_KEYS_SUCCESS) {
    return {
      ...state,
      ListingSecurityKeyLoading: false,
      ListingSecurityKeyData: action.payload,
      // assetsSelectsUpdated: false,
      // realVolumeKeysSelectsUpdated: false,
      // marketCapKeysSelectsUpdated: false,
      // ListingHoldersConcentrationKeyUpdated: false,
      // listingMaturityKeyUpdated: false,
    };
  } else if (action.type === LISTING_SECURITY_KEYS_FAIL) {
    return {
      ...state,
      ListingSecurityKeyLoading: false,
      ListingSecurityKeyError: action.payload,
    };
  } else if (action.type === LISTING_SECURITY_VALUE_REQUEST) {
    return {
      ...state,
      ListingSecurityValueLoading: true,
    };
  } else if (action.type === LISTING_SECURITY_VALUE_SUCCESS) {
    return {
      ...state,
      ListingSecurityValueLoading: false,
      ListingSecurityValueData: action.payload,
      assetsSelectsUpdated: false,
      realVolumeKeysSelectsUpdated: false,
      marketCapKeysSelectsUpdated: false,
      ListingHoldersConcentrationKeyUpdated: false,
      listingMaturityKeyUpdated: false,
      listingRegulationKeyUpdated: false,
      listingGeneralKeyUpdated: false,
      listingSecurityKeyUpdated: false,
    };
  } else if (action.type === LISTING_SECURITY_VALUE_FAIL) {
    return {
      ...state,
      ListingSecurityValueLoading: false,
      ListingSecurityValueError: action.payload,
    };
  } else if (action.type === LISTING_SECURITY_KEYS_UPDATED) {
    return {
      ...state,
      listingSecurityKeyUpdated: true,
    };
  } else if (action.type === LISTING_GENERAL_KEYS_REQUEST) {
    /*General */
    return {
      ...state,
      ListingGeneralKeyLoading: true,
    };
  } else if (action.type === LISTING_GENERAL_KEYS_SUCCESS) {
    return {
      ...state,
      ListingGeneralKeyLoading: false,
      ListingGeneralKeyData: action.payload,
      // assetsSelectsUpdated: false,
      // realVolumeKeysSelectsUpdated: false,
      // marketCapKeysSelectsUpdated: false,
      // ListingHoldersConcentrationKeyUpdated: false,
      // listingMaturityKeyUpdated: false,
    };
  } else if (action.type === LISTING_GENERAL_KEYS_FAIL) {
    return {
      ...state,
      ListingGeneralKeyLoading: false,
      ListingGeneralKeyError: action.payload,
    };
  } else if (action.type === LISTING_GENERAL_VALUE_REQUEST) {
    return {
      ...state,
      ListingGeneralValueLoading: true,
    };
  } else if (action.type === LISTING_GENERAL_VALUE_SUCCESS) {
    return {
      ...state,
      ListingGeneralValueLoading: false,
      ListingGeneralValueData: action.payload,
      assetsSelectsUpdated: false,
      realVolumeKeysSelectsUpdated: false,
      marketCapKeysSelectsUpdated: false,
      ListingHoldersConcentrationKeyUpdated: false,
      listingMaturityKeyUpdated: false,
      listingRegulationKeyUpdated: false,
      listingGeneralKeyUpdated: false,
      listingSecurityKeyUpdated: false,
    };
  } else if (action.type === LISTING_GENERAL_VALUE_FAIL) {
    return {
      ...state,
      ListingGeneralValueLoading: false,
      ListingGeneralValueError: action.payload,
    };
  } else if (action.type === LISTING_GENERAL_KEYS_UPDATED) {
    return {
      ...state,
      listingGeneralKeyUpdated: true,
    };
  } else if (action.type === LISTING_REGULATION_KEYS_REQUEST) {
    /*REGULATION */
    return {
      ...state,
      ListingRegulationKeyLoading: true,
    };
  } else if (action.type === LISTING_REGULATION_KEYS_SUCCESS) {
    return {
      ...state,
      ListingRegulationKeyLoading: false,
      ListingRegulationKeyData: action.payload,
      // assetsSelectsUpdated: false,
      // realVolumeKeysSelectsUpdated: false,
      // marketCapKeysSelectsUpdated: false,
      // ListingHoldersConcentrationKeyUpdated: false,
      // listingMaturityKeyUpdated: false,
    };
  } else if (action.type === LISTING_REGULATION_KEYS_FAIL) {
    return {
      ...state,
      ListingRegulationKeyLoading: false,
      ListingRegulationKeyError: action.payload,
    };
  } else if (action.type === LISTING_REGULATION_VALUE_REQUEST) {
    return {
      ...state,
      ListingRegulationValueLoading: true,
    };
  } else if (action.type === LISTING_REGULATION_VALUE_SUCCESS) {
    return {
      ...state,
      ListingRegulationValueLoading: false,
      ListingRegulationValueData: action.payload,
      assetsSelectsUpdated: false,
      realVolumeKeysSelectsUpdated: false,
      marketCapKeysSelectsUpdated: false,
      ListingHoldersConcentrationKeyUpdated: false,
      listingMaturityKeyUpdated: false,
      listingRegulationKeyUpdated: false,
      listingGeneralKeyUpdated: false,
      listingSecurityKeyUpdated: false,
    };
  } else if (action.type === LISTING_REGULATION_VALUE_FAIL) {
    return {
      ...state,
      ListingRegulationValueLoading: false,
      ListingRegulationValueError: action.payload,
    };
  } else if (action.type === LISTING_REGULATION_KEYS_UPDATED) {
    return {
      ...state,
      listingRegulationKeyUpdated: true,
    };
  } else if (action.type === SAVE_AS_EXCEL) {
    return {
      ...state,
      exportData: action.payload,
    };
  }
  return state;
}

export const getRealVolumeData = (state) => state.ListingReducer.realVolumeData;
export const getMarketCapData = (state) => state.ListingReducer.marketCapData;
export const getListingAssets = (state) => state.ListingReducer.assets;
export const getSelectedAssets = (state) => state.ListingReducer.selectedAssets;
export const getIsAssetsSelectsUpdated = (state) =>
  state.ListingReducer.assetsSelectsUpdated;
export const getListingRealVolumeKeys = (state) =>
  state.ListingReducer.realVolumeKeys;
export const getIsRealVolumeSelectsUpdated = (state) =>
  state.ListingReducer.realVolumeKeysSelectsUpdated;
export const getListingMarketCapKeys = (state) =>
  state.ListingReducer.marketCapKeys;
export const getListingMarketCapUpdatedKeys = (state) =>
  state.ListingReducer.marketCapKeysSelectsUpdated;

export const getListingHoldersConcentrationKeyData = (state) =>
  state.ListingReducer.ListingHoldersConcentrationKeyData;
export const getListingHoldersConcentrationValueData = (state) =>
  state.ListingReducer.ListingHoldersConcentrationValueData;
export const getListingHoldersConcentrationUpdatedKeys = (state) =>
  state.ListingReducer.ListingHoldersConcentrationKeyUpdated;

export const getListingMaturityKeyData = (state) =>
  state.ListingReducer.ListingMaturityKeyData;
export const getListingMaturityValueData = (state) =>
  state.ListingReducer.ListingMaturityValueData;
export const getlistingMaturityUpdatedKeys = (state) =>
  state.ListingReducer.listingMaturityKeyUpdated;

export const getListingSecurityKeyData = (state) =>
  state.ListingReducer.ListingSecurityKeyData;
export const getListingSecurityValueData = (state) =>
  state.ListingReducer.ListingSecurityValueData;
export const getlistingSecurityUpdatedKeys = (state) =>
  state.ListingReducer.listingSecurityKeyUpdated;

export const getListingGeneralKeyData = (state) =>
  state.ListingReducer.ListingGeneralKeyData;
export const getListingGeneralValueData = (state) =>
  state.ListingReducer.ListingGeneralValueData;
export const getlistingGeneralUpdatedKeys = (state) =>
  state.ListingReducer.listingGeneralKeyUpdated;

export const getListingRegulationKeyData = (state) =>
  state.ListingReducer.ListingRegulationKeyData;
export const getListingRegulationValueData = (state) =>
  state.ListingReducer.ListingRegulationValueData;
export const getlistingRegulationUpdatedKeys = (state) =>
  state.ListingReducer.listingRegulationKeyUpdated;

export const getExportedData = (state) => state.ListingReducer?.exportData;
