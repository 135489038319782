export const exportToCSV = async (exportData) => {
  // Create a Blob from the CSV buffer
  const blob = new Blob([exportData.data], { type: "text/csv" });

  // Create a link element, set the URL and download attributes
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = "listing-assets.xlsx";

  // Append link to body and trigger click to download
  document.body.appendChild(link);
  link.click();

  // Clean up by removing the link from the document
  document.body.removeChild(link);
};
