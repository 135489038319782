import {
  ApiGetListingAssets,
  ApiGetListingMarketCapKeys,
  ApiGetListingMarketCup,
  ApiGetListingRealVolume,
  ApiGetListingRealVolumeKeys,
  ApiGetSelectedAssets,
  ApiPutListingMarketCapKeys,
  ApiPutListingRealVolumeKeys,
  AssetsSelect,
  ApiGetListingHoldersConcentrationValue,
  ApiGetListingHoldersConcentrationKeys,
  ApiGetListingMaturityKeys,
  ApiGetListingMaturityValue,
  ApiPutHoldersConcentrationCapKeys,
  ApiPutListingMaturityKeys,
  ApiGetListingSecurityValue,
  ApiGetListingSecurityKeys,
  ApiPutListingSecurityKeys,
  ApiGetListingGeneralKeys,
  ApiGetListingGeneralValue,
  ApiPutListingGeneralKeys,
  ApiGetListingHoldersConcentration,
  ApiGetListingMaturity,
  ApiGetListingGeneral,
  ApiGetListingSecurity,
  ApiExportExcel,
} from "../Api";
import {
  ASSETS_DATA,
  SELECT_ASSETS_DATA,
  MARKET_CAP_DATA,
  REAL_VOLUME_DATA,
  ASSETS_SELECTS_CHANGED,
  REAL_VOLUME_KEYS,
  MARKET_CAP_KEYS,
  REAL_VOLUME_KEYS_UPDATED,
  MARKET_CAP_KEYS_UPDATED,
  LISTING_HOLDERS_CONCENTRATION_KEYS_REQUEST,
  LISTING_HOLDERS_CONCENTRATION_KEYS_FAIL,
  LISTING_HOLDERS_CONCENTRATION_KEYS_SUCCESS,
  LISTING_HOLDERS_CONCENTRATION_VALUE_REQUEST,
  LISTING_HOLDERS_CONCENTRATION_VALUE_SUCCESS,
  LISTING_HOLDERS_CONCENTRATION_VALUE_FAIL,
  LISTING_MATURITY_KEYS_REQUEST,
  LISTING_MATURITY_KEYS_SUCCESS,
  LISTING_MATURITY_KEYS_FAIL,
  LISTING_MATURITY_VALUE_REQUEST,
  LISTING_MATURITY_VALUE_SUCCESS,
  LISTING_HOLDERS_CONCENTRATION_KEYS_UPDATED,
  LISTING_MATURITY_KEYS_UPDATED,
  LISTING_SECURITY_KEYS_REQUEST,
  LISTING_SECURITY_KEYS_SUCCESS,
  LISTING_SECURITY_KEYS_FAIL,
  LISTING_SECURITY_VALUE_REQUEST,
  LISTING_SECURITY_VALUE_SUCCESS,
  LISTING_SECURITY_VALUE_FAIL,
  LISTING_SECURITY_KEYS_UPDATED,
  LISTING_GENERAL_KEYS_REQUEST,
  LISTING_GENERAL_KEYS_SUCCESS,
  LISTING_GENERAL_KEYS_FAIL,
  LISTING_GENERAL_VALUE_REQUEST,
  LISTING_GENERAL_VALUE_SUCCESS,
  LISTING_GENERAL_VALUE_FAIL,
  LISTING_GENERAL_KEYS_UPDATED,
  HOLDERS_CONCENTRATION_DATA,
  MATURITY_DATA,
  SECURITY_DATA,
  GENERAL_DATA,
  SAVE_AS_EXCEL,
} from "../Reducer/ListeningReducer";

export function ExportExcelThunk() {
  return (dispatch) => {
    ApiExportExcel().then((result) => {
      dispatch({
        type: SAVE_AS_EXCEL,
        payload: {
          data: result?.data,
        },
      });
    });
  };
}

export function realVolumeThunk(data) {
  return (dispatch) => {
    ApiGetListingRealVolume(data).then((result) => {
      dispatch({
        type: REAL_VOLUME_DATA,
        payload: {
          data: result?.data?.data,
        },
      });
    });
  };
}

export function getSelectedAssetsThunk() {
  return (dispatch) => {
    ApiGetSelectedAssets().then((result) => {
      dispatch({
        type: SELECT_ASSETS_DATA,
        payload: {
          data: result?.data?.data,
        },
      });
    });
  };
}

export function AssetsSelectThunk(list) {
  return (dispatch) => {
    AssetsSelect(list).then(() => {
      dispatch({
        type: ASSETS_SELECTS_CHANGED,
      });
    });
  };
}

export function marketCapThunk(data) {
  return (dispatch) => {
    ApiGetListingMarketCup(data).then((result) => {
      dispatch({
        type: MARKET_CAP_DATA,
        payload: {
          data: result?.data?.data,
        },
      });
    });
  };
}

export function holdersConcentrationThunk(data) {
  return (dispatch) => {
    ApiGetListingHoldersConcentration(data).then((result) => {
      dispatch({
        type: HOLDERS_CONCENTRATION_DATA,
        payload: {
          data: result?.data?.data,
        },
      });
    });
  };
}

export function maturityThunk(data) {
  return (dispatch) => {
    ApiGetListingMaturity(data).then((result) => {
      dispatch({
        type: MATURITY_DATA,
        payload: {
          data: result?.data?.data,
        },
      });
    });
  };
}
export function securityThunk(data) {
  return (dispatch) => {
    ApiGetListingSecurity(data).then((result) => {
      dispatch({
        type: SECURITY_DATA,
        payload: {
          data: result?.data?.data,
        },
      });
    });
  };
}
export function generalThunk(data) {
  return (dispatch) => {
    ApiGetListingGeneral(data).then((result) => {
      dispatch({
        type: GENERAL_DATA,
        payload: {
          data: result?.data?.data,
        },
      });
    });
  };
}

export function ListingAssetsThunk() {
  return (dispatch) => {
    ApiGetListingAssets().then((result) => {
      dispatch({
        type: ASSETS_DATA,
        payload: {
          data: result?.data?.data,
        },
      });
    });
  };
}

export function realVolumeKeysThunk() {
  return (dispatch) => {
    ApiGetListingRealVolumeKeys().then((result) => {
      dispatch({
        type: REAL_VOLUME_KEYS,
        payload: {
          data: result?.data?.data,
        },
      });
    });
  };
}

export function realVolumeKeysUpdateThunk(data) {
  return (dispatch) => {
    ApiPutListingRealVolumeKeys(data).then(() => {
      dispatch({
        type: REAL_VOLUME_KEYS_UPDATED,
      });
    });
  };
}

export function marketCapKeysThunk() {
  return (dispatch) => {
    ApiGetListingMarketCapKeys().then((result) => {
      dispatch({
        type: MARKET_CAP_KEYS,
        payload: {
          data: result?.data?.data,
        },
      });
    });
  };
}

export function marketCapKeysUpdateThunk(data) {
  return (dispatch) => {
    ApiPutListingMarketCapKeys(data).then(() => {
      dispatch({
        type: MARKET_CAP_KEYS_UPDATED,
      });
    });
  };
}

export const ListingHoldersConcentrationKeysThunk = () => {
  return async (dispatch) => {
    dispatch({
      type: LISTING_HOLDERS_CONCENTRATION_KEYS_REQUEST,
    });
    try {
      const response = await ApiGetListingHoldersConcentrationKeys();
      const { data } = response;
      dispatch({
        type: LISTING_HOLDERS_CONCENTRATION_KEYS_SUCCESS,
        payload: data?.data,
      });
    } catch ({ response }) {
      const { data } = response;
      dispatch({
        type: LISTING_HOLDERS_CONCENTRATION_KEYS_FAIL,
        payload:
          typeof data?.error?.message === "string"
            ? data?.error?.message
            : data?.error?.message[0],
      });
    }
  };
};

export const ListingHoldersConcentrationValueThunk = () => {
  return async (dispatch) => {
    dispatch({
      type: LISTING_HOLDERS_CONCENTRATION_VALUE_REQUEST,
    });
    try {
      const response = await ApiGetListingHoldersConcentrationValue();
      const { data } = response;
      dispatch({
        type: LISTING_HOLDERS_CONCENTRATION_VALUE_SUCCESS,
        payload: data?.data,
      });
    } catch ({ response }) {
      const { data } = response;
      dispatch({
        type: LISTING_HOLDERS_CONCENTRATION_VALUE_FAIL,
        payload:
          typeof data?.error?.message === "string"
            ? data?.error?.message
            : data?.error?.message[0],
      });
    }
  };
};

export function holdersConcentrationKeyUpdateThunk(data) {
  return (dispatch) => {
    ApiPutHoldersConcentrationCapKeys(data).then(() => {
      dispatch({
        type: LISTING_HOLDERS_CONCENTRATION_KEYS_UPDATED,
      });
    });
  };
}

export const ListingMaturityKeysThunk = () => {
  return async (dispatch) => {
    dispatch({
      type: LISTING_MATURITY_KEYS_REQUEST,
    });
    try {
      const response = await ApiGetListingMaturityKeys();
      const { data } = response;
      dispatch({
        type: LISTING_MATURITY_KEYS_SUCCESS,
        payload: data?.data,
      });
    } catch ({ response }) {
      const { data } = response;
      dispatch({
        type: LISTING_MATURITY_KEYS_FAIL,
        payload:
          typeof data?.error?.message === "string"
            ? data?.error?.message
            : data?.error?.message[0],
      });
    }
  };
};

export const ListingMaturityValueThunk = () => {
  return async (dispatch) => {
    dispatch({
      type: LISTING_MATURITY_VALUE_REQUEST,
    });
    try {
      const response = await ApiGetListingMaturityValue();
      const { data } = response;
      dispatch({
        type: LISTING_MATURITY_VALUE_SUCCESS,
        payload: data?.data,
      });
    } catch ({ response }) {
      const { data } = response;
      dispatch({
        type: LISTING_HOLDERS_CONCENTRATION_VALUE_FAIL,
        payload:
          typeof data?.error?.message === "string"
            ? data?.error?.message
            : data?.error?.message[0],
      });
    }
  };
};
export function maturityKeyUpdateThunk(data) {
  return (dispatch) => {
    ApiPutListingMaturityKeys(data).then(() => {
      dispatch({
        type: LISTING_MATURITY_KEYS_UPDATED,
      });
    });
  };
}
/*Security Thunks */

export const ListingSecurityKeysThunk = () => {
  return async (dispatch) => {
    dispatch({
      type: LISTING_SECURITY_KEYS_REQUEST,
    });
    try {
      const response = await ApiGetListingSecurityKeys();
      const { data } = response;
      dispatch({
        type: LISTING_SECURITY_KEYS_SUCCESS,
        payload: data?.data,
      });
    } catch ({ response }) {
      const { data } = response;
      dispatch({
        type: LISTING_SECURITY_KEYS_FAIL,
        payload:
          typeof data?.error?.message === "string"
            ? data?.error?.message
            : data?.error?.message[0],
      });
    }
  };
};

export const ListingSecurityValueThunk = () => {
  return async (dispatch) => {
    dispatch({
      type: LISTING_SECURITY_VALUE_REQUEST,
    });
    try {
      const response = await ApiGetListingSecurityValue();
      const { data } = response;
      dispatch({
        type: LISTING_SECURITY_VALUE_SUCCESS,
        payload: data?.data,
      });
    } catch ({ response }) {
      const { data } = response;
      dispatch({
        type: LISTING_SECURITY_VALUE_FAIL,
        payload:
          typeof data?.error?.message === "string"
            ? data?.error?.message
            : data?.error?.message[0],
      });
    }
  };
};
export function securityKeyUpdateThunk(data) {
  return (dispatch) => {
    ApiPutListingSecurityKeys(data).then(() => {
      dispatch({
        type: LISTING_SECURITY_KEYS_UPDATED,
      });
    });
  };
}

/*General Thunks */

export const ListingGeneralKeysThunk = () => {
  return async (dispatch) => {
    dispatch({
      type: LISTING_GENERAL_KEYS_REQUEST,
    });
    try {
      const response = await ApiGetListingGeneralKeys();
      const { data } = response;
      dispatch({
        type: LISTING_GENERAL_KEYS_SUCCESS,
        payload: data?.data,
      });
    } catch ({ response }) {
      const { data } = response;
      dispatch({
        type: LISTING_GENERAL_KEYS_FAIL,
        payload:
          typeof data?.error?.message === "string"
            ? data?.error?.message
            : data?.error?.message[0],
      });
    }
  };
};

export const ListingGeneralValueThunk = () => {
  return async (dispatch) => {
    dispatch({
      type: LISTING_GENERAL_VALUE_REQUEST,
    });
    try {
      const response = await ApiGetListingGeneralValue();
      const { data } = response;
      dispatch({
        type: LISTING_GENERAL_VALUE_SUCCESS,
        payload: data?.data,
      });
    } catch ({ response }) {
      const { data } = response;
      dispatch({
        type: LISTING_GENERAL_VALUE_FAIL,
        payload:
          typeof data?.error?.message === "string"
            ? data?.error?.message
            : data?.error?.message[0],
      });
    }
  };
};
export function generalKeyUpdateThunk(data) {
  return (dispatch) => {
    ApiPutListingGeneralKeys(data).then(() => {
      dispatch({
        type: LISTING_GENERAL_KEYS_UPDATED,
      });
    });
  };
}
